
































import {Vue, Component} from "vue-property-decorator";
import PassLoginType from "@/views/Login/PassLogin/PassLogin";
import ZoomPage from "@/impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import PassLoginCont from "@/views/Login/PassLogin/components/Cont/index.vue";
import PassLoginFooter from "@/views/Login/PassLogin/components/Footer/index.vue";
import {getPassLogin} from "@/views/Login/PassLogin/Server";
import {Toast} from "vant";
import {IndexStore} from "@/store/modules/Index";

@Component({name: "PassLogin",components:{ PassLoginCont,PassLoginFooter,HeadTop }})
export default class PassLogin extends ZoomPage implements PassLoginType{
    IcoIcon = require("@/assets/ico/Ico.png")
    Account = "";
    PassWord = "";
    loadingShow = true

    mounted(){
        this.loadingShow = false
    }

    handleGetData(e:{ account: string; passWord: string }){
        let { account,passWord } = e
        this.Account = account
        this.PassWord = passWord
    }

    handleClickBtn(){
        getPassLogin({ password:this.PassWord,phone:this.Account }).then(res=>{
            if( !( ( res.name || res.nickName ) && res.phone && res.headImg && res.school && res.schoolName ) ){
                Toast({
                    message:"跳转补全信息",
                    icon:"none",
                    className:"ToastClassName"
                })
                let time = setTimeout(()=>{
                    this.$router.replace({
                        name:"vTMessage"
                    })
                    clearTimeout( time )
                },1000)
            }else{
                if( !res.phone ){ // 重新注册绑定
                    this.$router.replace({
                        path:"/userSetPass",
                    })
                    return;
                }
                if ( !(res.schoolName && res.school ) ){ // 跳转到 绑定学校
                    this.$router.replace({
                        path:"/school",
                    })
                    return;
                }
                IndexStore.SetLoading( true )
                this.$router.replace({
                    name:"home"
                })
            }
        })
    }
}
